import Capitalize from "../../utilities/Capitalize";
import FormatCenterName from "../../utilities/FormatCenterName";
import IsBot from "../../utilities/IsBot";
import useFetch from "./useFetch";
import { Link } from "react-router-dom";
import { getImageUrl } from "../../utilities/getImageUrl";
import React, { useState, useEffect } from 'react';

const CentersVipCarousel = ({ id, caller }) => {
  const [isBot, setIsBot] = useState("true");
  const [logoUrls, setLogoUrls] = useState({}); // Oggetto per memorizzare gli URL dei loghi

  const apiPath = caller === 'service' ? 'adv_services_near_service' : 'adv_centers_near_center';
  const title = caller === 'service' ? 'Centri medici e ambulatori vicini con la stessa prestazione sanitaria' : 'Centri medici e ambulatori vicini';

  const url = `${process.env.REACT_APP_HTTP_PROTO}://${process.env.REACT_APP_BE_HOST}/api/v1/${apiPath}/${id}?is_bot=${isBot}`;

  const { data, loading, error } = useFetch(url);

  useEffect(() => {
    setIsBot(IsBot());
  }, []);

  // Usa useEffect per aggiornare i loghi per ogni centro
  useEffect(() => {
    if (data && Array.isArray(data.data)) {
      data.data.forEach(async (center) => {
        const url = await getImageUrl(center.logo);
        setLogoUrls(prevLogoUrls => ({ ...prevLogoUrls, [center.slug]: url })); // Salva l'URL del logo in base allo slug
      });
    }
  }, [data]);

  if (loading) {
    return null;
  }

  if (error) return `Errore: ${error.message}`;

  if (!data || (Array.isArray(data.data) && data.data.length === 0)) {
    console.log(`No data returned from GET ${url}`);
    return null;
  }

  return (
    <>
      <div className="related-centers slgo-single-center-related-centers mb-5">
        <div className="container pt-0 pb-5">
          <div className="row">
            <div className="col-md-12 pb-3 px-3 px-sm-0">
              <h4 className="slgo-light-text font-weight-bold">{title}</h4>
            </div>
          </div>
          <div className="row row-grid px-3 px-sm-0">
            {data?.data.map((center) => (
              <div className="slgo-single-related-center" key={center.slug}>
                <div className="slgo-img-single-center-related-centers p-md-2">
                  <a href={`/centro-medico/${center.slug}`} className="slgo-center-img d-block mb-md-3">
                    {/* Usa l'URL specifico per il logo del centro */}
                    <img
                      src={logoUrls[center.slug]} // Usa l'URL del logo corrispondente
                      width="240"
                      height="194"
                      className="slgo-img-h-auto"
                      alt={Capitalize(center.center_name)}
                    />
                  </a>
                  <h6 className="slgo-light-text p-0 my-0 mx-md-0 mx-2">
                    <a href={`/centro-medico/${center.slug}`}>{FormatCenterName(center.center_name)}</a>
                  </h6>
                </div>
                <div className="slgo-contact-related-center p-md-2">
                  {center.phone_number && (
                    <a href={`tel://${center.phone_number}`} className="btn btn-primary btn-no-arrow btn-only-icon slgo-btn-single-center slgo-btn-phone"></a>
                  )}
                  {center.emails && (
                    <a href={`mailto:${center.emails}`} className="btn btn-primary btn-no-arrow btn-only-icon slgo-btn-single-center slgo-btn-email"></a>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default CentersVipCarousel;
